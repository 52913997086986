import './style.scss';


console.log('hello');


/** スクロールしたら実行されるアニメーション */
(function () {
    let targets = document.querySelectorAll('.js_scrollAnim'); //アニメーションさせたい要素

    function scrollAnim() {
        var scroll = window.scrollY; //スクロール量を取得
        var windowHeight = window.innerHeight; //画面の高さを取得
        for (let target of targets) { //ターゲット要素がある分、アニメーション用のクラスをつける処理を繰り返す
            var targetPos = target.getBoundingClientRect().top + scroll; //ターゲット要素の位置を取得
            if (scroll > targetPos - windowHeight) {
                target.classList.add('js_activeAnim');
            }
        }
    }

    document.addEventListener('DOMContentLoaded', function () {
        scrollAnim();
    });

    //スクロールイベント
    window.addEventListener('scroll', function () {
        scrollAnim();
    });
}());

(function () {
    document.addEventListener('DOMContentLoaded', function () {
        /** 画像拡大 アニメ */
        let elBtn = document.querySelector('.js_visionBtn');
        let elImg = document.querySelector('.js_visionImg');
        let elImgbox = document.querySelector('.js_lagerImgbox');
        let elOverlay = document.querySelector('.js_overlay');
        let elImgCloseBtn = document.querySelector('.js_imgCloseBtn');

        elBtn.addEventListener('click', () => {
            showImg()
        });

        if (elImg) {
            elImg.addEventListener('click', () => {
                showImg()
            });
        }

        function showImg() {
            if (!elImgbox.classList.contains('js_active')) {
                // open
                elImgbox.classList.add('js_active');
                elOverlay.classList.add('js_active');
            }
        }

        elImgCloseBtn.addEventListener('click', () => {
            if (elImgbox.classList.contains('js_active')) {
                // close
                elImgbox.classList.remove('js_active');
                elOverlay.classList.remove('js_active');
            }
        });

        elOverlay.addEventListener('click', () => {
            if (elImgbox.classList.contains('js_active')) {
                // close
                elImgbox.classList.remove('js_active');
                elOverlay.classList.remove('js_active');
            }
        });
    });
}());